import UtmLocalStorage from '@otovo/shared/lib/analytics/queryParams/UtmLocalStorage';

/**
 * Stores (or updates) UTM to local storage and Google Analytics.
 * Intended to be used exclusively for UTM keys derived from a SanityDocument.
 *
 * A change of UTM data can trigger a new Google Analytics session. More details
 * at https://support.google.com/analytics/answer/2731565
 */
export function storeUtmData({
  utmSource,
  utmCampaign,
  utmContent,
  searchParams,
}: {
  utmSource?: string;
  utmCampaign?: string;
  utmContent?: string;
  searchParams: Record<string, string | string[]>;
}) {
  const existingUtmData = UtmLocalStorage.retrieve();
  const utmData = {
    utm_source:
      utmSource || searchParams.utm_source || existingUtmData.utm_source,
    utm_campaign:
      searchParams.utm_campaign || utmCampaign || existingUtmData.utm_campaign,
    utm_content: searchParams.utm_content || utmContent,
    utm_medium: searchParams.utm_medium,
    utm_term: searchParams.utm_term,
    utm_id: searchParams.utm_id,
    gclid: searchParams.gclid || existingUtmData.google_ads_click_id,
    referrer: existingUtmData.referrer,
  };
  UtmLocalStorage.store(utmData);
}
